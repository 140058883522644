// extracted by mini-css-extract-plugin
export var ArtistDescription = "Cutrone-module--ArtistDescription--DyTuI";
export var ArtistInfos = "Cutrone-module--ArtistInfos--PpSvs";
export var ButtonWrapper = "Cutrone-module--ButtonWrapper --kNEnh";
export var CardWrapper = "Cutrone-module--CardWrapper--jDlsg";
export var CarrouselWrapper2 = "Cutrone-module--CarrouselWrapper2--Cm4ph";
export var Citations = "Cutrone-module--Citations--Bjk8C";
export var DescriptionWrapper = "Cutrone-module--DescriptionWrapper--pB9O8";
export var ImageWrapper = "Cutrone-module--ImageWrapper--mZ3+F";
export var LinkWrapper = "Cutrone-module--LinkWrapper--XGYZl";
export var MobileProtrait = "Cutrone-module--MobileProtrait--JhJg8";
export var More = "Cutrone-module--More--BG-NV";
export var MoreButton = "Cutrone-module--MoreButton--WVhJE";
export var NameWrapper = "Cutrone-module--NameWrapper--TB7kE";
export var PdpWrapper = "Cutrone-module--PdpWrapper--UIc6c";
export var PhotosWrapper = "Cutrone-module--PhotosWrapper--42uBD";
export var ProfilWrapper = "Cutrone-module--ProfilWrapper--rob2C";
export var TitleWrapper = "Cutrone-module--TitleWrapper--oYdKN";
export var Wrapper = "Cutrone-module--Wrapper--LK2G5";