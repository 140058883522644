import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Cutrone.module.css";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';
import Button from "../../../../components/Button";
import Pdp from '../../../../res/cutrone/portrait.png'
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "Ronnie Cutrone",
  name: "Ronnie Cutrone",
  description:
  "Ronald Curtis Cutrone est né à New York le 10 juillet 1948 et a étudié à la School of Visual Arts de Manhattan. Il peut être considéré comme le père spirituel des artistes de la rue. Il a été l'assistant de studio de Warhol de 1972 à 1980. Il a été un membre actif de l'avant-garde nocturne new-yorkaise lorsque, en 1978, un bar du centre-ville, le Mudd Club, lancé par Steve Mass, est devenu un laboratoire branché pour les bizarreries et la créativité. Le Mudd rassemblait toutes sortes de personnes, des punks et post-punks purs et durs, aux artistes, en passant par les passionnés de mode et les cadres de Wall Street. Le Mudd Club représentait toutes les formes d'art. Ronnie Cutrone a construit une cage pour ce club, qui a servi de scène à Grace Jones. Des marchands d'art comme Tony Shafrazi, Annina Nosei et Mary Boone ont reconnu ces événements à plusieurs niveaux et ont eu la clairvoyance de découvrir des artistes comme Cutrone. De nombreux autres clubs ont ouvert leurs portes à East Village au début des années 80. Le Palladium, méga discothèque créée à la fin des années 80, a été personnalisé par les interventions artistiques de Haring, Basquiat et Scharf. À partir de 1982 environ, après avoir quitté le studio de Warhol, Ronnie Cutrone se consacre à sa propre peinture. Son oeuvre présente les caractéristiques du Pop Art, qui s'inspire de l'imagerie des dessins animés. Il a peint des personnages de dessins animés comme Woody Woodpecker et Donald Duck sur des drapeaux américains et ceux d'autres nations. \"J\'ai toujours cherché à créer un langage universel\", dit Cutrone, \"un langage visuel par lequel tous ceux qui le voient peuvent immédiatement en comprendre la signification. Pour moi, c\'est l\'essence même de l\'art populaire. \"",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Ronnie Cutrone.",
  photos: [
    // { src: PastPresent1, name: "Quik" },
    // { src: PastPresent2, name: "Quik" },
    // { src: PastPresent3, name: "Quik" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};

const Quik = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
     
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>RONNIE CUTRONE <h2 style={{paddingLeft: "16px"}}>(Ronald Curtis Cutrone - American)</h2></h1>
          <h3 className={NameWrapper} >Born in 1948</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
      </div>
      </div>
      
      
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default Quik;